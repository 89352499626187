/**
 * From: https://gist.github.com/joelambert/1002116
 */

// @ts-ignore
window.requestAnimFrame = (function () {
    // @ts-ignore
    return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.oRequestAnimationFrame || window.msRequestAnimationFrame || function (callback, element) {
        window.setTimeout(callback, 1000 / 60);
    };
})();

// @ts-ignore
window.requestTimeout = function (fn, delay) {
    // @ts-ignore
    if (!window.requestAnimationFrame && !window.webkitRequestAnimationFrame && !(window.mozRequestAnimationFrame && window.mozCancelRequestAnimationFrame) && !window.oRequestAnimationFrame && !window.msRequestAnimationFrame)
        return window.setTimeout(fn, delay);

    var start = new Date().getTime(),
        handle = new Object();

    function loop() {
        var current = new Date().getTime(),
            delta = current - start;

        // @ts-ignore
        delta >= delay ? fn.call() : handle.value = requestAnimFrame(loop);
    };

    // @ts-ignore
    handle.value = requestAnimFrame(loop);
    return handle;
};
